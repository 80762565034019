import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import * as siteMapUtils from "../utils/SitemapUtils";
import { convertToSentenceCase } from "../utils/StringUtils";

export default () => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query {
        allSitePage {
          edges {
            node {
              id
              path
              context {
                frontmatter {
                  blogCategory
                  date
                  menuText
                  summary
                  title
                  overview
                }
              }
            }
          }
        }
      }
    `
  );

  const allPagesMetaData = siteMapUtils.removeCoreSitePagesFrom(allSitePage.edges);
  const productPages = siteMapUtils.getProductPageArrayFrom(allPagesMetaData);
  const productCategories = siteMapUtils.getProductCategoriesFrom(productPages);
  const blogPages = siteMapUtils.getBlogPageArrayFrom(allPagesMetaData);
  const blogDateGroups = siteMapUtils.getBlogDateArrayFrom(blogPages);
  const sitePages = siteMapUtils.getSitePageArrayFrom(allPagesMetaData);

  return (
    <Layout>
      <section className="container mx-auto mb-6">
        <h1>Sitemap</h1>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden lg:w-1/3 xl:w-1/3">
            <h2>Products</h2>
            {productCategories.map((category) => {
              return (
                <div>
                  <h3>{convertToSentenceCase(category)}</h3>
                  {productPages
                    .filter((p) => p.productCategory === category)
                    .map((page) => {
                      return (
                        <p>
                          <Link to={page.data.node.path}>{page.data.node.context.frontmatter.menuText}</Link>
                        </p>
                      );
                    })}
                </div>
              );
            })}
          </div>

          <div className="w-full overflow-hidden lg:w-1/3 xl:w-1/3">
            <h2>Blogs</h2>
            {blogDateGroups.map((group) => {
              return (
                <div>
                  <h3>{group}</h3>
                  {blogPages
                    .filter((p) => p.blogGroup === group)
                    .map((page) => {
                      return (
                        <p>
                          <Link to={page.data.node.path}>{page.data.node.context.frontmatter.title}</Link>
                        </p>
                      );
                    })}
                </div>
              );
            })}
          </div>

          <div className="w-full overflow-hidden lg:w-1/3 xl:w-1/3">
            <h2>Others</h2>
            {sitePages.map((page) => {
              return (
                <p>
                  <Link to={page.node.path}>{siteMapUtils.getFormattedSitePageLinkTextFrom(page.node.id)}</Link>
                </p>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};
