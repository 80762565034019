import React from "react";
import PropTypes from "prop-types";
import { SEO } from "../SEO";
import Navbar from "../Navbar";
import PageFooter from "../PageFooter";
import WindowSizeContext from "../../contexts/windowSizeContext";
import useWindowSize from "../../hooks/useWindowSize";
import useIpGeoData from "../../hooks/useIpGeoData";
import IpGeoDataContext from "../../contexts/ipGeoDataContext";

import "../../css/core.css";

/**
 * The default layout component for non MDX rendered content.
 *
 * This layout page sets the WindowSizeContext from the useWindowSize hook.
 *
 * @param {Props} props Array of data passed to the function from the parent component and the rendering pipeline.
 * @returns (element) A layout page, configured with the meta data passed, which renders the child elements passed.
 */
const Layout = ({ pageTitle, pageDescription, pageImagePath, pageMeta, children, showNavbar }) => {
  const windowSize = useWindowSize();
  const ipGeoData = useIpGeoData();

  return (
    <WindowSizeContext.Provider value={windowSize}>
      <IpGeoDataContext.Provider value={ipGeoData}>
        <div className="flex flex-col min-h-screen font-body">
          <SEO title={pageTitle} description={pageDescription} pageImagePath={pageImagePath} meta={pageMeta} />
          {showNavbar ? (
            <header>
              <Navbar />
            </header>
          ) : null}
          <main className="content">{children}</main>
          <footer>
            <PageFooter />
          </footer>
        </div>
      </IpGeoDataContext.Provider>
    </WindowSizeContext.Provider>
  );
};

Layout.propTypes = {
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  pageImagePath: PropTypes.string,
  pageMeta: PropTypes.arrayOf(PropTypes.object),
  showNavbar: PropTypes.bool,
};

Layout.defaultProps = {
  showNavbar: true,
};

export default Layout;
